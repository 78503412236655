import * as React from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useQueryClient } from '@tanstack/react-query';
import {
  useEntity,
  useConfigAppParams,
  useConfigAppPath,
  useSearchParam,
  useConfigUserFeedback,
  useEntityOperationError,
} from '@web-config-app/core-react';
import {
  useEntityDetailsProps,
  DataSourceStoreProvider,
} from '@web-config-app/entity-form';
import {
  EntityDetailsPageContainer,
  EntityDetailsPage,
} from '@web-config-app/core-react-containers';
import { EntityDetail } from '@web-config-app/core';
import { openInTab } from '@leagueplatform/web-common';

const EntityDetailsPageWithContext = ({
  instanceId,
}: {
  instanceId?: string;
}) => {
  const history = useHistory();
  const {
    formData,
    formSchema,
    formPath,
    onFormDataChange,
    handleNodeToggle,
    entityTree,
    operations,
    expandedNodes,
    resetEntityTreeNodes,
  } = useEntityDetailsProps();

  /**
   * This will reset the entity tree expanded state store whenever we
   * load a new entity instance.
   */
  React.useEffect(() => {
    resetEntityTreeNodes();
  }, [instanceId, resetEntityTreeNodes]);

  React.useEffect(() => {
    /**
     * When landing on the route initially we want to set the first entity tree
     * node's id as url param in order to open and mark the first node as active
     */
    const isInstanceDataLoaded = Boolean(instanceId && operations.get.data);
    if (
      (!formPath || formPath === '') &&
      (isInstanceDataLoaded || !instanceId)
    ) {
      const [firstNode] = entityTree;
      const searchParams = new URLSearchParams({ path: firstNode.id });
      history.push({
        search: searchParams.toString(),
      });
    }
  }, [formPath, entityTree, history, instanceId, operations.get.data]);

  const { isError, isLoading } = operations.get;

  return (
    <EntityDetailsPage
      isLoading={isLoading}
      isError={isError}
      formData={formData}
      formSchema={formSchema}
      formPath={formPath}
      entityTree={entityTree}
      handleNodeToggle={handleNodeToggle}
      onFormDataChange={onFormDataChange}
      expandedNodes={expandedNodes}
    />
  );
};

interface EntityDetailsTestProps {
  /**
   * The __testOptions prop was added to make it possible to mock leagueFetch. It allows the test cases
   * to pass in pretty much whatever's needed. Though, for now, it's only the `fetchFn` prop being
   * passed to the `useEntityOperations` hooks.
   *
   * This was added to mitigate an issue mocking a dependency of a dependency (in this case, `leagueFetch` being
   * a dependency of `@web-config-app/core-react` -> `web-config-app/api` -> `@leagueplatform/league-fetch`) which
   * has proven difficult, even following some github issue discussions. See:
   *
   * https://github.com/vitest-dev/vitest/issues/1336
   * https://github.com/vitest-dev/vitest/issues/3936
   *
   * This prop should not be used in the production app.
   */
  _testOptions?: any;
}

export const EntityDetails = ({ _testOptions }: EntityDetailsTestProps) => {
  const { domainId, entityId, entityInstanceId } = useConfigAppParams<{
    domainId: string;
    entityId: string;
    entityInstanceId: string;
  }>();
  const { getEntityDetailsPathForId } = useConfigAppPath({
    domainIdParam: domainId,
    entityIdParam: entityId,
  });
  const history = useHistory();
  const queryClient = useQueryClient();

  const entity = useEntity({ domainId, entityId });

  const path = useSearchParam('path') ?? '';

  const hasEntityId = Boolean(entityInstanceId);

  const { resetConfigUserFeedbackStore } = useConfigUserFeedback((state) => ({
    resetConfigUserFeedbackStore: state.resetConfigUserFeedbackStore,
  }));

  const handleEntityOperationError = useEntityOperationError();

  /**
   *Reset on unmount of the page
   */
  React.useEffect(
    () => () => {
      resetConfigUserFeedbackStore();
    },
    [resetConfigUserFeedbackStore],
  );

  return (
    <DataSourceStoreProvider>
      <EntityDetailsPageContainer
        entity={entity}
        instanceId={entityInstanceId}
        path={path}
        createDefaultEntityData={!hasEntityId}
        options={{
          get: {
            ..._testOptions?.get,
          },
          update: {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: [entity.endpoints.list.path],
              });
              queryClient.invalidateQueries({
                queryKey: [entity.endpoints.get.path, entityInstanceId],
              });
              /**
               * TODO: Uncomment and enable reset after https://everlong.atlassian.net/browse/CACT-1355 is ready
               * On success, we want to reset the entity operation api errors
               */
              // resetConfigUserFeedbackStore();
            },
            onError: (error: any) => {
              handleEntityOperationError(error);
            },
            ..._testOptions?.update,
          },
          create: {
            onSuccess: ({ data }: { data: EntityDetail }) => {
              queryClient.invalidateQueries({
                queryKey: [entity.endpoints.list.path],
              });
              const newEntityId = data?.id;
              history.push(getEntityDetailsPathForId(newEntityId));
              /**
               * TODO: Uncomment and enable reset after https://everlong.atlassian.net/browse/CACT-1355 is ready
               * On success, we want to reset the entity operation api errors
               */
              // resetConfigUserFeedbackStore();
            },
            onError: (error: any) => {
              handleEntityOperationError(error);
            },
            ..._testOptions?.create,
          },
          duplicateAsDraft: {
            onSuccess: ({ data }: { data: EntityDetail }) => {
              queryClient.invalidateQueries({
                queryKey: [entity.endpoints.list.path],
              });

              const newEntityId = data?.id;
              openInTab(getEntityDetailsPathForId(newEntityId));
              /**
               * TODO: Uncomment and enable reset after https://everlong.atlassian.net/browse/CACT-1355 is ready
               * On success, we want to reset the entity operation api errors
               */
              // resetConfigUserFeedbackStore();
            },
            onError: (error: any) => {
              handleEntityOperationError(error);
            },
          },
          ..._testOptions?.duplicateAsDraft,
        }}
      >
        <EntityDetailsPageWithContext instanceId={entityInstanceId} />
      </EntityDetailsPageContainer>
    </DataSourceStoreProvider>
  );
};
